// import Store from './components/Store.vue';
import Stores from './components/stores/Stores.vue'
import Orders from './components/orders/Orders.vue'
import OrderDetails from './components/orders/Order.vue';
import SupplierOrderDetails from './components/orders/SupplierViewOrder.vue';
import StoreProducts from './components/stores/Store.vue';
import ShoppingCart from './components/ShoppingCart.vue';
import ProductDetails from './components/ProductDetails.vue';
import Login from './components/auth/Login.vue';
import Password from './components/auth/Password.vue';
import Account from './components/auth/Account.vue';
import Register from './components/auth/Register.vue';

export const routes = [
	// {path: '/', component: Store, name: 'mainpage'},
	{path: '/', component: Stores, name: 'mainpage'},
	{path: '/stores', component: Stores, name: 'stores'},
	{path: '/stores/store/:storeId', component: StoreProducts, name: 'store', props:true},
	{path: '/orders', component: Orders, name: 'orders'},
	{path: '/orders/order', component: OrderDetails, name: 'order'},
	{
		path: '/supplier/comfirm-order', 
		component: SupplierOrderDetails, 
		name:'confirmorder',  
		onlyGuest: true,
		meta: {
			hideCart: true,
			hideMenu: true,
			hideLogo: true,
		}
	},
	{path: '/product/:id', component: ProductDetails, name: 'product'},
	{path: '/cart/:storeId', component: ShoppingCart, name: 'shoppingcart', props:true},
	{path: '/login', component: Login, name: 'login', onlyGuest: true },
	{path: '/password', component: Password, name: 'password', onlyGuest: true },
	{path: '/account', component: Account, name: 'account'},
	{path: '/register', component: Register, name: 'register', onlyGuest: true},
	{path: '*', component: Stores, name: 'mainpage' }
	//{path: '*', redirect: '/' }
];