<template>
  <div class=" mt-7">
    <div class="row ">
      <div class="col-md-8">
        <h1 class="font-weight-bold mb-0">Order Summary</h1>
        <h6 class="mb-5 text-color-grey">Supplier: {{ storeName }}</h6>
         <!-- <b-table id="cart" hover small :items="cartItemList" :fields="cartFields">
    <template #cell(product)="row">
      {{ row.item.product }}
    </template>
    <template #cell(price)="row">
      {{ row.item.price }}
    </template>
    <template #cell(quantity)="row">
      {{ row.item.quantity }}
    </template>
    <template #cell(subtotal)="row">
      {{ row.item.subtotal }}
    </template>
    <template #cell()></template>
  </b-table>-->
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <table id="cart" class="table  table-sm" width="500">
        <thead>
          <tr>
            <th width="70%">Product</th>
            <th width="10%">Size</th>
            <!-- <th width="10%">Price</th> -->
            <th width="20%" class="text-center">Quantity</th>
            <!-- <th width="20%" class="text-center">Subtotal</th> -->
            <th width="10%"></th>
          </tr>
        </thead>
        <transition-group name="list-shopping-cart" tag="tbody">
          <app-cart-item
            v-for="cartItem in cart"
            :cartItem="cartItem"
            :key="cartItem.id"
          ></app-cart-item>
        </transition-group>

        <!-- <transition-group name="list-shopping-cart" tag="tbody">
          <template v-for="storeId in uniqueStoreIds">
            <app-cart-item
              v-for="cartItem in getCartItemsByStoreId(storeId)"
              :cartItem="cartItem"
              :key="cartItem.id"
            ></app-cart-item>
          </template>
        </transition-group> -->
        
        <tfoot>
          <tr class="d-table-row d-sm-none">
            <td class="text-center">
              <strong>Total R{{ cartValue }}</strong>
            </td>
          </tr>
          <tr>
            <td>
              <p class="w-100 mt-2 m-0">Additional Notes</p>
              <textarea class="w-100" rows="5" v-model="notes"></textarea>
              <!-- <button class="btn btn-secondary btn-sm  font-weight-bold" @click="saveShoppingCartLocal">
                 Save 
              </button> -->
            </td>
            <td colspan="2" class="d-none d-sm-table-cell text-right" style="vertical-align: bottom;">
              <div class="" v-if="!showBackOnline">
                <p class="m-0">
                  {{
                    !onLine
                      ? 'You are Offline...'
                      : showBackOnline
                      ? 'Back Online...'
                      : message
                  }}
                </p>
              </div>
            </td>
            <!-- <td class="d-none d-sm-table-cell text-center">
              <strong>Total R{{ cartValue }}</strong>
            </td> -->
            <td class="px-0" style="vertical-align: bottom;">
              <button class="btn btn-dark  btn-sm font-weight-bold" @click="checkout" :disabled="isButtonDisabled || !onLine">
                <span class="text-nowrap">Place Order </span>
                <i v-if="isLoadingBtn" class="fa fa-spinner fa-spin"></i>
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '../config/firebaseConfig';
import { mapActions, mapGetters } from 'vuex';
import CartItem from './cart/CartItem.vue';

export default {
  props: ['storeId'],
  data() {
    return {
      isLoadingBtn: false,
      isButtonDisabled: false,
      onLine: navigator.onLine,
      showBackOnline: false,
      message: '',
      cartFields: [
        { key: 'product', label: 'Product', sortable: false },
        { key: 'price', label: 'Price', sortable: false },
        { key: 'quantity', label: 'Quantity', sortable: false },
        { key: 'subtotal', label: 'Subtotal', sortable: false },
        { key: 'actions', label: '', sortable: false },
      ],
      storeName: '',
      notes: '',
      cart: {}
    };
  },
  computed: {
    ...mapGetters([
      'cartItemList',
      'isLoggedIn',
      'products',
      'currentUser',
      'cartValue',
    ]),

    uniqueStoreIds() {
      // return Array.from(new Set(this.cartItemList.map(item => item.storeId)));
      // return this.cartItemList[0][this.storeId];
      return this.cartItemList.find(element => element.storeId == this.storeId);
    }
  },
  created(){
    this.fetchStoreDetails(this.storeId);
  },
  mounted(){
    this.fetchCartList();
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  components: {
    appCartItem: CartItem,
  },
  methods: {
    ...mapActions([
      'saveShoppingCart',
      'addMessage',
      'saveToTransaction',
      'clearCart',
    ]),
    fetchCartList(){
      const storelist = this.$store.state.cart.cartItemList.find(element => element.storeId == this.storeId);
      // console.log(storelist)
      if(storelist[this.storeId].length === 0){
        this.showCartBtn = false;
        this.itemsInCartNo = 0;
      }else{
        this.cart = storelist[this.storeId]
        this.itemsInCartNo = storelist[this.storeId].length;
        this.showCartBtn = true;
      }
      // const suppCart = this.$store.state.cart.cartItemList[0][this.storeId]
    },
    fetchStoreDetails(storeId) {
    // console.log(storeId);
      return ref
        .child(`stores/${storeId}`)
        .once('value')
        .then(snapshot => {
          const store = snapshot.val();
          if (store) {
            this.storeName = store.name;
            return store;
          } else {
            throw new Error(`Store with ID ${storeId} does not exist.`);
          }
        });
    },
    getCartItemsByStoreId(storeId) {
      // return this.cartItemList.filter(item => item.storeId === storeId);
      return this.cartItemList[0][this.storeId].filter(item => item.storeId === storeId);
    },
    checkValidCart(itemList, prodList) {
      let isValid = true
      let message = ''

      itemList.map((item) => {
        for (let prodIdx = 0; prodIdx < prodList.length; prodIdx++) {
          if (prodList[prodIdx].id == item.id) {
            if (prodList[prodIdx].quantity < item.quantity) {
              message = `Only ${prodList[prodIdx].quantity} ${item.title} available in stock`
              isValid = false
              return
            }
            break
          }
        }
      })
      return {
        isValid,
        message,
      }
    },
    // sendEmail(){
    //   const emailData = {
    //     to: 'phebeon.vera@gmail.com', // Update with your email address
    //     subject: 'New Order',
    //     message: 'Order Information: ' + JSON.stringify(this.cartItemList)
    //     };

    //     console.log(JSON.stringify(this.cartItemList));
    //     // Make an HTTP POST request to your PHP script
    //     axios.post('https:/opencharge.io/sendEmail.php', emailData)
    //     .then(response => {
    //       console.log('Email sent successfully');
    //       console.log(response);
    //     })
    //     .catch(error => {
    //       console.error('Error sending email:', error);
    //     });      
    // },
    saveShoppingCartLocal() {
      if (this.isLoggedIn) {
        let { isValid, message } = this.checkValidCart(
          this.cartItemList,
          this.products
        )

        if (isValid) {
          this.saveShoppingCart({
            cartItemList: this.cartItemList,
            uid: this.currentUser.uid,
          }).then(() => {
            this.addMessage({
              messageClass: 'success',
              message: 'Your shopping cart is saved successfully',
            })
            this.$router.push('/')
          })
        } else {
          this.addMessage({
            messageClass: 'danger',
            message: message,
          })
        }
      } else {
        this.addMessage({
          messageClass: 'warning',
          message: 'Please login to save your cart',
        })
      }
    },
    checkout() {
      if (this.isLoggedIn) {
        if (!this.cartItemList || this.cartItemList.length == 0) {
          this.addMessage({
            messageClass: 'warning',
            message: 'Your cart is empty!',
          })
          return
        }
        let { isValid, message } = this.checkValidCart(
          this.cartItemList,
          this.products
        )
        this.isButtonDisabled = true;
        if (isValid) {
          this.isLoadingBtn = true;
          this.saveToTransaction({
            cartItemList: this.cartItemList[0][this.storeId],
            uid: this.currentUser.uid,
            cartValue:this.cartValue,
            notes: this.notes,
          }).then(() => {
            this.addMessage({
              //messageClass: 'success',
              //message: 'Your order has been successfully processed!',
            })
            this.saveShoppingCart({
              cartItemList: [],
              uid: this.currentUser.uid,
            })
            //this.sendEmail()
            this.clearCart()
            this.isLoadingBtn = false;
            this.$router.push('/orders')
          })
        } else {
          this.addMessage({
            messageClass: 'danger',
            message: message,
          })
        }
      } else {
        this.addMessage({
          messageClass: 'warning',
          message: 'Please login to checkout',
        })
      }
    },
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true
        setTimeout(() => {
          this.showBackOnline = false
        }, 1000)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.list-shopping-cart-leave-active {
  transition: all 0.4s;
}

.mt-7{
  margin-top: 7rem;
}

.list-shopping-cart-leave-to {
  opacity: 0;
  transform: translateX(50px);
}

.table-sm {
  font-size: 0.875rem;
  ::v-deep h4 {
    font-size: 1.0rem;
  }
}

.btn-dark {
    color: #fff;
    background-color: #000;
    border-color: #000;
}
.text-color-grey{
color: gray;
	
}
</style>
