import firebase from 'firebase'

// const config = {
//   apiKey: "AIzaSyDg1LjGvaoQHTg5pik6H1SLRofI8-XBdQA",
//   authDomain: "oneorder-co.firebaseapp.com",
//   databaseURL: "https://oneorder-co.firebaseio.com/"
// }

const config = {
  apiKey: "AIzaSyBQdGAGT43B_Q6PsjegW2D-27bn2GEb-tc",
  authDomain: "oneorder-co.firebaseapp.com",
  databaseURL: "https://one-order-a278c-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "one-order-a278c",
}

firebase.initializeApp(config);

export function firebaseListener(func) {
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      // console.log("User log in success", user);
      func(true, user)
    } else {
      // console.log("User log in failed", user);
      func(false)
    }
  }, function(error) {
    console.log(error)
  });
}

export const ref = firebase.database().ref();
export const firebaseAuth = firebase.auth;



